<!-- eslint-disable vue/no-v-html -->
<template>
  <vue-modal
    ref="modal"
    size="lg"
    @close="$emit('close')"
  >
    <template slot="title">
      Template Editor
    </template>
    <template slot="body">
      <LetterLayoutsSelect
        ref="layoutSelect"
        v-model="form.letter_layout_id"
        null-option="No Layout"
        new-option="+ Create new Layout"
        @change="onSelectLetterLayout"
      />
      <form-text
        v-model="form.title"
        label="Title"
        icon="description"
        :max-chars="120"
      />

      <div class="alert alert-info mt-3">
        Select the required Package Items to be able to create this letter for the programme
      </div>
      <PackageItemsSelect
        v-model="form.package_items"
        label="Related Package Items"
        multiple
      />

      <LetterPagesEditor
        :pages="form.pages"
      />

      <md-button
        class="md-info md-block md-sm"
        @click="showHtmlInfo = !showHtmlInfo"
      >
        <md-icon>info</md-icon> Template Info
      </md-button>
      <div
        v-if="showHtmlInfo"
        class="description"
      >
        <button
          class="btn btn-transparent"
          @click="showHtmlInfo = false"
        >
          <md-icon>close</md-icon>
        </button>
        <div
          v-html="description"
        />
      </div>

      <p class="mt-4">
        <b>Email Template</b> Used when pressed SEND EMAIL button in Programme Letters page.
      </p>

      <form-text
        v-model="form.email_subject"
        label="Email Subject"
        icon="description"
        class="mb-0"
      />

      <md-button
        class="md-info md-block md-sm"
        @click="showEmailHtmlInfo = !showEmailHtmlInfo"
      >
        <md-icon>info</md-icon> Subject Info
      </md-button>
      <div
        v-if="showEmailHtmlInfo"
        class="description"
      >
        <button
          class="btn btn-transparent"
          @click="showEmailHtmlInfo = false"
        >
          <md-icon>close</md-icon>
        </button>
        <div
          v-html="subject_description"
        />
      </div>

      <small style="margin-bottom: -10px; font-weight: 600">Template</small>
      <trumbowyg
        v-model="form.email_html"
        :config="config"
        class="mt-0"
        name="resultHtml"
      />
    </template>
    <template slot="footer">
      <md-button
        class="md-success float-right"
        :disabled="isLoading"
        @click="saveTemplate"
      >
        <div v-if="!isLoading">
          Save Letter
        </div>
        <div v-else>
          Loading
        </div>
      </md-button>
    </template>
  </vue-modal>
</template>

<script>
import { FormText } from '@/components/Inputs';
import { PackageItemsSelect, LetterLayoutsSelect } from '@/components/Inputs/selects';
import LetterPagesEditor from '@/components/LetterPagesEditor';
import emptyTemplate from './empty_template.txt';
import templateDesc from './_letterTemplateDesc.txt';
import subjectDesc from './_subjectTemplateDesc.txt';

export default {
  components: {
    FormText,
    PackageItemsSelect,
    LetterLayoutsSelect,
    LetterPagesEditor,
  },
  props: {
    template: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        letter_template_id: null,
        letter_layout_id: null,
        title: null,
        html: null,
        email_html: null,
        package_items: [],
        pages: [
          { html: null, order: 0, title: 'Page 1' },
        ],
      },
      currentPageIx: 0,
      pagesOpen: false,
      pageLoading: false,
      isLoading: false,
      showEmailHtmlInfo: false,
      showHtmlInfo: false,
      description: null,
      subject_description: null,
      config: {
        btns: [
          ['viewHTML'],
          ['undo', 'redo'], // Only supported in Blink browsers
          ['formatting'],
          ['strong', 'em', 'del'],
          ['superscript', 'subscript'],
          ['link'],
          ['insertImage'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['unorderedList', 'orderedList'],
          ['horizontalRule'],
          ['removeformat'],
        ],
        semantic: {
          div: 'div', // Editor does nothing on div tags now
        },
      },
    };
  },
  mounted() {
    if (this.template) {
      this.form = JSON.parse(JSON.stringify(this.template));
      this.form.package_items = this.template.package_items
        .map((x) => x.package_item_id);
    } else {
      this.form.pages[0].html = emptyTemplate;
    }

    this.description = templateDesc;

    this.subject_description = subjectDesc;
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },

    async saveTemplate() {
      const form = JSON.parse(JSON.stringify(this.form));

      if (this.form.letter_template_id) {
        await this.request(this.$API.LETTER_TEMPLATES + this.template.letter_template_id, 'put', form, () => {
          this.fireSuccess('Template updated successfully!');
          this.close();
          setTimeout(() => {
            this.$emit('saved');
          }, 200);
        });
      } else {
        await this.request(this.$API.LETTER_TEMPLATES, 'post', form, () => {
          this.fireSuccess('Template created successfully!');
          this.close();
          setTimeout(() => {
            this.$emit('saved');
          }, 200);
        });
      }
    },
    onSelectLetterLayout(layout) {
      if (layout.id === 'new') {
        this.$emit('newLayout');
      }
    },
    selectLastLayout() {
      this.$refs.layoutSelect.selectLast();
    },
  },
};
</script>

<style scoped lang="scss">
.description {
  background: #ccc;
  padding: 1.5em;
  border-radius: .5em;
  -webkit-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.15);
  box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.15);
  color: #161515;
  position: relative;
}

.description .btn {
position: absolute;
top: 1em;
right: 1em;
}
</style>
