<template>
  <vue-modal
    ref="modal"
    size="lg"
    @close="$emit('close')"
  >
    <template slot="title">
      Letter Layout Form
    </template>
    <template slot="body">
      <FormText
        v-model="form.title"
        label="Title"
      />

      <FormUploader
        ref="uploader"
        multiple
        class="mb-4"
        @input="handleFileUpload"
      >
        <md-button class="md-info form-uploader--button">
          <md-icon>file_present</md-icon>
          Upload files
        </md-button>
      </FormUploader>

      <md-switch v-model="form.landscape">
        Is Landscape
      </md-switch>

      <div
        class="letter-previewer"
        :class="{landscape: form.landscape}"
      >
        <div class="letter-previewer-text">
          <div class="title">
            Lorem ipsum dolor sit amet
          </div>
          <div class="content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            In id aliquam turpis.
            Integer fermentum metus et pharetra convallis.
            Pellentesque iaculis justo nec quam pulvinar gravida.
            Aenean pellentesque magna et placerat viverra.
            Aliquam venenatis, lacus a ullamcorper venenatis, enim
            sem pharetra lacus, id laoreet ante tellus sed orci.
            Nam sem ante, semper a purus ut, aliquam dapibus elit.
            Aliquam bibendum sagittis est, sit amet aliquam nulla pretium vel.
          </div>
          <div class="content">
            Sed quis libero ante. Aenean eleifend lectus vel
            ipsum faucibus, eget euismod massa rhoncus.
            Sed sagittis, purus eu viverra venenatis,
            ipsum dui consequat purus, vel efficitur purus
            nunc nec diam. Nulla non sem neque. Interdum
            et malesuada fames ac ante ipsum primis in faucibus.
            Praesent ultricies at nunc ut aliquam.
            Etiam efficitur felis tortor, in vulputate massa porta id.
            Proin non sapien accumsan, sagittis turpis a,
            ullamcorper nunc. Mauris blandit luctus lacus,
            eu tincidunt lectus lacinia interdum.
            Vestibulum elementum aliquet arcu et rutrum.
          </div>
        </div>

        <vue-draggable-resizable
          v-for="(image, index) of letter_files"
          :key="image.key"
          :x="image.pos_x"
          :y="image.pos_y"
          :w="image.width"
          :h="image.height"
          :lock-aspect-ratio="true"
          :draggable="true"
          :resizable="true"
          :parent="true"
          :style="{ zIndex: image.z_index }"
          @mouseover.native="(image.showActions = true)"
          @mouseleave.native="(image.showActions = false)"
          @resizing="(pos_x, pos_y, width, height) => onResize(pos_x, pos_y, width, height, image)"
          @dragging="(pos_x, pos_y) => onDrag(pos_x, pos_y, image)"
        >
          <img
            :src="image.url"
            :alt="image.alt"
            :title="image.alt"
            style="object-fit: contain; width: 100%;"
          >
          <md-chip
            v-show="image.showActions"
            class="md-primary btn-front"
            @click="changeImageZIndex(index, 'front')"
          >
            Front
          </md-chip>
          <md-chip
            v-show="image.showActions"
            class="md-primary btn-back"
            @click="changeImageZIndex(index, 'back')"
          >
            Back
          </md-chip>
          <md-chip
            v-show="image.showActions"
            class="md-primary btn-delete"
            @click="deleteImage(index)"
          >
            <md-icon
              class="md-icon"
              with="16px"
              color="white"
            >
              close
            </md-icon>
          </md-chip>
        </vue-draggable-resizable>
      </div>
    </template>
    <template slot="footer">
      <md-button
        class="md-success pull-right"
        @click="saveLayout"
      >
        Save
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import { FormText } from '@/components/Inputs';
import FormUploader from '@/components/Inputs/FormUploader.vue';
import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
import debounce from '@/utils/debounce.js';

export default {
  components: {
    FormText,
    FormUploader,
    VueDraggableResizable,
  },
  props: {
    letterLayout: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    form: {
      title: null,
      landscape: false,
    },
    letter_files: [],
  }),
  computed: {
    isPreview() {
      return this.letterLayout;
    },
  },
  mounted() {
    if (this.isPreview) {
      this.request(
        `${this.$API.LETTER_LAYOUTS}/${this.letterLayout.letter_layout_id}`,
        'get',
        {},
        ({ data }) => {
          this.form.title = this.letterLayout.title;
          this.form.landscape = this.letterLayout.landscape;
          this.letter_files = this.parseFromOriginalToPreview(data);
        },
      );
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    validateForm() {
      let isValidForm = true;

      if (!this.form.title) {
        this.fireError('Please, set a title for this layout');
        isValidForm = false;
      }

      if (!this.letter_files.length) {
        this.fireError('Please, upload at least one picture.');
        isValidForm = false;
      }

      if (this.letter_files.length > 10) {
        this.fireError('Please, upload at maximum 10 files.');
        isValidForm = false;
      }

      return isValidForm;
    },
    async saveLayout() {
      if (!this.validateForm()) return;

      const originals = this.parseFromPreviewToOriginal(this.letter_files);

      const formData = new FormData();

      formData.append('title', this.form.title);
      formData.append('landscape', this.form.landscape);
      originals.forEach(({ file, url, ...details }, ix) => {
        if (file) {
          // Added 3rd parameter (filename) to identify file in backend
          formData.append('files', file, details.identifier);
        }
        formData.append(`files_details[${ix}]`, JSON.stringify(details));
      });

      if (this.isPreview) {
        this.request(
          `${this.$API.LETTER_LAYOUTS}/${this.letterLayout.letter_layout_id}`,
          'put',
          formData,
          () => {
            this.fireSuccess('Letter layout updated successfully');
            this.$emit('onSaved');
            this.close();
          },
        );
      } else {
        this.request(
          this.$API.LETTER_LAYOUTS,
          'post',
          formData,
          () => {
            this.fireSuccess('Letter layout created successfully');
            this.$emit('onSaved');
            this.close();
          },
        );
      }
    },
    changeImageZIndex(imageIndex, action) {
      const actions = {
        front: () => {
          this.letter_files[imageIndex].z_index += 1;
        },
        back: () => {
          if (this.letter_files[imageIndex].z_index > 0) {
            this.letter_files[imageIndex].z_index -= 1;
          }
        },
      };

      actions[action]();
    },
    deleteImage(index) {
      this.letter_files.splice(index, 1);
    },
    handleFileUpload(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const imageUrl = e.target.result;

        const img = new Image();

        img.src = imageUrl;

        img.onload = () => {
          const originalWidth = img.width;
          const originalHeight = img.height;

          const containerWidth = this.form.landscape ? 707 : 500;
          const containerHeight = this.form.landscape ? 500 : 707;

          const widthScale = containerWidth / originalWidth;
          const heightScale = containerHeight / originalHeight;

          const scale = Math.min(widthScale, heightScale);

          const adjustedWidth = originalWidth * scale;
          const adjustedHeight = originalHeight * scale;

          this.letter_files.push({
            url: imageUrl,
            pos_x: 0,
            pos_y: 0,
            width: adjustedWidth,
            height: adjustedHeight,
            z_index: 1,
            showActions: false,
            file,
            key: Math.floor(Date.now() * Math.random()),
          });

          this.$refs.uploader.resetInput();
        };
      };

      reader.readAsDataURL(file);
    },
    onResize: debounce((pos_x, pos_y, width, height, image) => {
      image.pos_x = pos_x;
      image.pos_y = pos_y;
      image.width = width;
      image.height = height;
    }),
    onDrag: debounce((pos_x, pos_y, image) => {
      image.pos_x = pos_x;
      image.pos_y = pos_y;
    }),
    parseFromPreviewToOriginal(letter_files) {
      const transformationRatio = 793.7 / 500;

      const originalSizes = letter_files.map(({
        url, pos_x, pos_y, width, height, z_index, letter_layout_image_id, file,
      }, index) => ({
        url,
        pos_x: (pos_x * transformationRatio),
        pos_y: (pos_y * transformationRatio),
        width: (width * transformationRatio),
        height: (height * transformationRatio),
        z_index,
        letter_layout_image_id,
        file,
        identifier: `file_${index}`,
      }));

      return originalSizes;
    },
    parseFromOriginalToPreview(letter_files) {
      const transformationRatio = 500 / 793.7;

      const originalSizes = letter_files.map(({
        url, pos_x, pos_y, width, height, z_index, letter_layout_image_id,
      }) => ({
        url,
        pos_x: (pos_x * transformationRatio),
        pos_y: (pos_y * transformationRatio),
        width: (width * transformationRatio),
        height: (height * transformationRatio),
        z_index,
        letter_layout_image_id,
        showActions: false,
        key: Math.floor(Date.now() * Math.random()),
      }));

      return originalSizes;
    },
  },
};
</script>

<style scoped lang="scss">

.form-uploader--button {
    min-width: 100%;
  }

  ::v-deep .form-uploader--button > .md-ripple {
    display: flex;
    justify-content: left;
  }

  .letter-previewer {
    position: relative;
    width: 500px;
    height: 707px;
    background-color: white;
    outline: 1px solid black;
    margin: auto;

    &.landscape {
      width: 707px;
      height: 499px;
    }
  }

  .letter-previewer .letter-previewer-text {
    position: absolute;
    pointer-events: none;
    top: 107px;
    bottom: 107px;
    left: 83px;
    right: 83px;
    padding: 0;
    z-index: 1050;
  }

  .letter-previewer-text .title {
    font-size: 9.4px;
    text-align: center;
  }

  .letter-previewer-text .content {
    font-size: 7.5px;
  }

  .image-container {
    inset: 0;
    position: absolute;
  }

  .btn-front {
    position: absolute;
    left: 0;
    top: -35px;
    cursor: pointer;
  }

  .btn-back {
    position: absolute;
    left: 54px;
    top: -35px;
    cursor: pointer;
  }

  .btn-delete {
    position: absolute;
    left: 108px;
    top: -35px;
    cursor: pointer;
  }

  .md-icon {
    font-size: 18px !important;
  }
</style>
