<template>
  <div
    class="letter-pages"
    :class="{ open: open }"
  >
    <div class="content">
      <div
        v-if="loading"
        class="loader-wrapper"
      >
        <Loader />
      </div>
      <template v-else>
        <FormHtml
          :value="pages[currentIx].html"
          :config="config"
          @input="pages[currentIx].html = $event"
        />

        <button
          class="pages-btn"
          @click="open = !open"
        >
          <i class="fa fa-file" />
          Pages ({{ pages.length }})
        </button>
      </template>
    </div>
    <div class="page-list">
      <div class="page-list-head">
        <h5>Pages</h5>
      </div>
      <ul>
        <li
          v-for="(p, pi) of pages"
          :key="pi"
          :class="{editting: pageIdEditting === pi}"
        >
          <div
            class="title"
            :class="{ active: pi === currentIx }"
            @click="showPage(pi)"
          >
            {{ p.title }}
          </div>
          <div class="title-editor">
            <input
              v-model="p.title"
              type="text"
              @keyup.enter="pageIdEditting = null"
            >
          </div>

          <button
            class="btn-info edit"
            @click="editPageName($event, pi)"
          >
            <i class="fa fa-pen" />
          </button>
          <button
            class="btn-info edit-save"
            @click="pageIdEditting = null"
          >
            <i class="fa fa-save" />
          </button>

          <button
            class="primary"
            :class="{
              disabled: pi === 0
            }"
            @click="movePage(pi, -1)"
          >
            <i class="fa fa-chevron-up" />
          </button>
          <button
            class="primary"
            :class="{
              disabled: pi === pages.length - 1
            }"
            @click="movePage(pi, 1)"
          >
            <i class="fa fa-chevron-down" />
          </button>
          <button
            :class="{
              disabled: pages.length <= 1
            }"
            @click="removePage(pi)"
          >
            <i class="fa fa-trash" />
          </button>
        </li>
      </ul>
      <button @click="newPage">
        <i class="fa fa-plus" />
        Add new Page
      </button>
    </div>
  </div>
</template>
<script>
import { FormHtml } from '@/components/Inputs';
import Loader from '@/components/loader';

export default {
  components: {
    FormHtml,
    Loader,
  },
  props: {
    pages: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    open: false,
    loading: false,
    currentIx: 0,
    pageIdEditting: null,
  }),
  mounted() {
    this.sortPages();
  },
  methods: {
    sortPages() {
      this.pages = this.pages.sort((a, b) => (a.order > b.order ? 1 : -1));
    },
    showPage(pi) {
      this.loading = true;

      setTimeout(() => {
        this.currentIx = pi;
        this.loading = false;
      }, 200);
    },
    newPage() {
      this.pages.push({
        key: Math.floor(Math.random() * Date.now()),
        html: '',
        order: this.pages.length,
        title: `Page ${this.pages.length + 1}`,
      });
      this.showPage(this.pages.length - 1);
    },
    removePage(pi) {
      this.fireConfirm(
        'Removing Letter Page',
        'The page data wont be available anymore. Are you sure?',
      ).then(() => {
        this.loading = true;

        this.pages.forEach((x, i) => {
          if (i > pi) {
            x.order -= 1;
          }
        });
        this.pages.splice(pi, 1);

        setTimeout(() => {
          this.currentIx = 0;
          this.loading = false;
        }, 200);
      });
    },
    movePage(index, change) {
      this.loading = true;
      setTimeout(() => {
        this.pages[index].order += change;
        this.pages[index + change].order -= change;
        this.sortPages();
        this.currentIx = index + change;
        this.loading = false;
      }, 200);
    },
    editPageName(evt, pi) {
      this.pageIdEditting = pi;
      setTimeout(() => {
        evt.target.parentElement.querySelector('input').focus();
      }, 100);
    },
  },
};
</script>
