<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-button
          class="md-round md-primary pull-right"
          @click="createNewLetterLayout"
        >
          <md-icon>add</md-icon>
          New Letter Layout
        </md-button>

        <vue-table
          v-if="vTable.headers"
          ref="vtable"
          :search="false"
          :values="vTable.values"
          :headers="vTable.headers"
          :actions="vTable.actions"
          :options="{
            tableClass:'table'
          }"
          @changed="getLetterLayouts"
          @onShowDetails="onShowDetails"
          @onDelete="onDelete"
          @onCopy="onCopy"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';

export default {
  components: {
    VueTable,
  },
  data() {
    return {
      filters: {
        paginate: true,
      },
      vTable: {
        headers: [
          {
            title: 'title',
            mask: 'Title',
            sortable: true,
          },
          {
            title: 'created_at',
            mask: 'Created At',
            dateFormat: true,
            sortable: true,
          },
          {
            title: 'updated_at',
            mask: 'Updated At',
            dateFormat: true,
            sortable: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Details',
            callback: 'onShowDetails',
            icon: 'search',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete Letter Layout',
            callback: 'onDelete',
            icon: 'close',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Copy Letter Layout',
            callback: 'onCopy',
            icon: 'content_copy',
          },
        ],
        values: {},
      },
      showModal: false,
      letterLayoutToPreview: null,
    };
  },
  mounted() {
    this.initTable();
  },
  methods: {
    initTable() {
      this.$refs.vtable.init();
    },
    getLetterLayouts(params) {
      this.request(this.$API.LETTER_LAYOUTS, 'get', { ...params, ...this.filters }, ({ data }) => {
        this.vTable.values = data;
      });
    },
    createNewLetterLayout() {
      this.$emit('openModal', null);
    },
    onShowDetails(item) {
      this.$emit('openModal', item);
    },
    onDelete(item) {
      this.fireConfirm(
        'Deleting Letter Layout',
        'Are you sure you want to Delete this Letter Layout? If there is a letter template with this layout, from now on, will have NO LAYOUT assigned.',
      ).then(() => {
        this.request(`${this.$API.LETTER_LAYOUTS}/${item.letter_layout_id}`, 'delete', null, () => {
          this.fireSuccess('Letter Layout deleted succesfully');
          this.$refs.vtable.init();
        });
      });
    },
    onCopy({ letter_layout_id }) {
      this.request(`${this.$API.LETTER_LAYOUTS}`, 'post', { letter_layout_id }, () => {
        this.fireSuccess('Letter layout copied successfuly');
        this.$refs.vtable.init();
        this.close();
      });
    },
  },
};
</script>
