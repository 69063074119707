<template>
  <div>
    <tabs
      :tab-name="[
        'Letter Templates',
        'Letter Layouts',
      ]"
      color-button="success"
      @change="onTabChange"
    >
      <template slot="tab-pane-1">
        <LetterTemplates
          ref="templates"
          @openModal="openTemplateModal"
        />
      </template>
      <template slot="tab-pane-2">
        <LetterLayouts
          ref="layouts"
          @openModal="openLayoutModal"
        />
      </template>
    </tabs>

    <LetterTemplatesForm
      v-if="showTemplateModal"
      ref="templatesForm"
      :template="selectedTemplate"
      @close="closeTemplateModal"
      @saved="templateSaved"
      @newLayout="openLayoutModal(null)"
    />

    <LetterLayoutForm
      v-if="showLayoutModal"
      :letter-layout="selectedLayout"
      @close="closeLayoutModal"
      @onSaved="layoutSaved"
    />
  </div>
</template>

<script>
import { Tabs } from '@/components';
import LetterTemplates from './LetterTemplates.vue';
import LetterLayouts from './LetterLayouts.vue';
import LetterLayoutForm from './LetterLayoutForm.vue';
import LetterTemplatesForm from './LetterTemplatesForm.vue';

export default {
  components: {
    LetterTemplates,
    LetterLayouts,
    Tabs,
    LetterLayoutForm,
    LetterTemplatesForm,
  },
  data: () => ({
    showLayoutModal: false,
    showTemplateModal: false,
    selectedLayout: null,
    selectedTemplate: null,
    activeTab: 'Letter Templates',
  }),
  methods: {
    // Tabs
    onTabChange(tab) {
      this.activeTab = tab;
    },

    // Layouts
    openLayoutModal(layout) {
      this.showLayoutModal = true;
      this.selectedLayout = layout;
    },
    closeLayoutModal() {
      this.showLayoutModal = false;
      this.selectedLayout = null;
    },
    layoutSaved() {
      if (this.activeTab === 'Letter Layouts') {
        this.$refs.layouts.initTable();
      } else if (this.showTemplateModal) {
        this.$refs.templatesForm.selectLastLayout();
      }
    },

    // Templates
    openTemplateModal(template) {
      this.showTemplateModal = true;
      this.selectedTemplate = template;
    },
    closeTemplateModal() {
      this.showTemplateModal = false;
      this.selectedTemplate = null;
    },
    templateSaved() {
      this.$refs.templates.initTable();
    },
  },
};
</script>

<style scoped>
  ::v-deep .tab-content > * {
    width: 100%;
  }
</style>
